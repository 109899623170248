import { Controller } from "@hotwired/stimulus";

import { create } from "frontoffice/services/bookingResourceSkusService";
import {
  fetchAvailability,
  fetchPrice,
} from "frontoffice/services/resourceSkusService";

export default class extends Controller {
  static targets = [
    "price",
    "pricing",
    "quantity",
    "bookButton",
    "errorMessage",
  ];

  static values = {
    price: String,
    startPrice: String,
    notAvailable: String,
    transferType: String,
    resourceSkuId: Number,
    bookingDates: Array,
    bookableTransfersCount: Number,
  };

  initialize() {
    [this.startsOn, this.endsOn] = this.bookingDatesValue;
    this.available = true;
  }

  connect() {
    this.fetchAvailabilityForQuantity();
    this.errorClasses = "border border-red-600".split(" ");
    this.resetForm();
  }

  priceValueChanged(value) {
    this.priceTarget.innerHTML = value;
  }

  quantityChanged() {
    if (
      this.startsOn &&
      this.endsOn &&
      this.quantityTarget.value &&
      this.bookableTransfersCountValue >= this.quantityTarget.value
    ) {
      this.fetchAvailabilityForQuantity();
      this.hideError();
    } else if (this.bookableTransfersCountValue < this.quantityTarget.value) {
      this.showError();
    }
  }

  toggleBookButton() {
    if (Number(this.quantityTarget.value) > 0 && this.available === true) {
      this.bookButtonTarget.disabled = false;
    } else {
      this.bookButtonTarget.disabled = true;
    }
  }

  updatePrice(price, quantity) {
    if (quantity === "0") {
      this.priceValue = this.startPriceValue;
      this.pricingTarget.classList.remove("hidden");
    } else {
      this.priceValue = price;
      this.pricingTarget.classList.add("hidden");
    }
  }

  fetchPrice() {
    const success = (response) => {
      this.updatePrice(response.price, this.quantityTarget.value);
    };
    const error = () => {
      this.updatePrice(this.notAvailableValue, 1);
    };

    fetchPrice({
      id: this.resourceSkuIdValue,
      startsOn: this.startsOn,
      endsOn: this.endsOn,
      quantity: Number(this.quantityTarget.value),
      success,
      error,
    });
  }

  fetchAvailabilityForQuantity() {
    const success = (response) => {
      this.available = response.availability;
      if (this.available) {
        this.fetchPrice();
      } else {
        this.updatePrice(this.notAvailableValue, this.quantityTarget.value);
      }
      this.toggleBookButton();
    };

    const error = () => {
      this.updatePrice(this.notAvailableValue, 1);
    };

    fetchAvailability({
      id: this.resourceSkuIdValue,
      startsOn: this.startsOn,
      endsOn: this.endsOn,
      entireDateRange: false,
      quantity: Number(this.quantityTarget.value),
      success,
      error,
    });
  }

  updateBookingTotalPrice() {
    const bookingResourceSkusChanged = new CustomEvent(
      "booking-resource-skus-changed",
      {
        bubbles: true,
        cancelable: true,
        composed: false,
      }
    );

    this.element.dispatchEvent(bookingResourceSkusChanged);
  }

  book(event) {
    // onward journey transfer data
    const data = {
      booking_resource_sku: {
        booking_id: this.element.dataset.bookingId,
        resource_sku_id: this.resourceSkuIdValue,
        starts_on: this.startsOn,
        ends_on: this.startsOn,
        quantity: Number(this.quantityTarget.value),
      }
    }

    const error = () => { };

    const success = (response) => {
      const onwardJourneyData = response.data;

      // create another resource sku for return journey
      data.booking_resource_sku.starts_on = this.endsOn;
      data.booking_resource_sku.ends_on = this.endsOn;

      create({
        data,
        success: (response) => { 
          const returnJourneyData = response.data;

          const bookingResourceSkuCreated = new CustomEvent("item-created", {
            detail: {
              transferType: this.transferTypeValue,
              onwardJourneyData: onwardJourneyData,
              returnJourneyData: returnJourneyData
            },
            bubbles: true,
            cancelable: true,
            composed: false,
          });

          event.target.dispatchEvent(bookingResourceSkuCreated);

          this.updateBookingTotalPrice();

          const bookingResourceSkuAdded = new CustomEvent("item-added", {
            detail: {
              transferType: this.transferTypeValue,
              quantity: Number(this.quantityTarget.value),
            }
          });

          window.dispatchEvent(bookingResourceSkuAdded);
        },
        error,
      });      
    };

    // create transfer for onward journey
    create({
      data,
      success,
      error,
    });
  }

  handleBooked(event) {
    if (this.transferTypeValue == event.detail.transferType) {
      this.bookableTransfersCountValue -= event.detail.quantity;
      this.resetForm();
    }    
  }

  handleRemoved(event) {
    if (this.transferTypeValue == event.detail.transferType) {
      this.bookableTransfersCountValue += event.detail.quantity;
      this.resetForm();
    }
  }

  resetForm() {
    this.errorMessageTarget.classList.add("hidden");
    this.quantityTarget.classList.remove(...this.errorClasses);
    this.quantityTarget.value = "0";
    this.updatePrice(null, "0");
    this.toggleBookButton();
  }

  hideError() {
    this.quantityTarget.classList.remove(...this.errorClasses);
    this.errorMessageTarget.classList.add("hidden");
  }

  showError() {
    this.quantityTarget.classList.add(...this.errorClasses);
    this.quantityTarget.blur();
    this.errorMessageTarget.classList.remove("hidden");
  }
}
