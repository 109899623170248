import { Controller } from "@hotwired/stimulus";
import { update as updatePosition } from "frontoffice/services/bookingResourceSkusService";

export default class extends Controller {
  static targets = [
    "participantSelectField",
    "participantsList",
    "dst",
    "button",
    "background",
    "errorMessage",
    "placeholder",
    "checkmark",
  ];

  static values = {
    bookingResourceSkuId: Number,
    bookingResourceSkuQuantity: Number,
    selectedParticipantsIds: Array,
    empty: String,
    invalid: String,
    skipQuantityCheck: Boolean,
  };

  static classes = ["active", "inactive", "valid", "invalid"];

  connect() {
    this.currentParticipantName = null;
    this.currentParticipant = null;

    this.nameList =
      this.dstTarget.innerText.length === 0
        ? []
        : this.dstTarget.innerText.split(", ");
  }

  toggleParticipantsList() {
    if (this.buttonTarget.getAttribute("aria-expanded") === "false") {
      this.backgroundTarget.classList.remove("hidden");
      this.participantsListTarget.classList.remove(...this.inactiveClasses);
      this.participantsListTarget.classList.add(...this.activeClasses);
      this.buttonTarget.setAttribute("aria-expanded", "true");
    } else {
      this.hideParticipantsList();
    }
  }

  hideParticipantsList(event) {
    if (
      event &&
      (this.buttonTarget.contains(event.target) ||
        this.participantsListTarget.contains(event.target))
    )
      return;

    if (this.buttonTarget.getAttribute("aria-expanded") === "true") {
      this.participantsListTarget.classList.add(...this.inactiveClasses);
      this.participantsListTarget.classList.remove(...this.activeClasses);
      this.buttonTarget.setAttribute("aria-expanded", "false");
      this.backgroundTarget.classList.add("hidden");
      this.buttonTarget.blur();
    }
  }

  selectedParticipantsIdsValueChanged() {
    const id = this.bookingResourceSkuIdValue;
    const data = {
      booking_resource_sku: {
        participant_ids: this.selectedParticipantsIdsValue,
      },
    };

    const success = () => {
      this.sendSelectedParticipantIds();
      this.highlightParticipantSelectField();
    };

    const error = () => {};

    updatePosition({
      id,
      data,
      success,
      error,
    });
  }

  sendSelectedParticipantIds() {
    this.element.dispatchEvent(
      new CustomEvent("selectedParticipantsIds", {
        detail: {
          bookingResourceSkuId: this.bookingResourceSkuIdValue,
          selectedParticipantsIds: this.selectedParticipantsIdsValue,
        },
        bubbles: true,
      })
    );
  }

  sendParticipant(id, name, bookingResourceSkuId, resourceSkuHandle) {
    this.element.dispatchEvent(
      new CustomEvent("participantAssigned", {
        detail: {
          id,
          name,
          bookingResourceSkuId,
          resourceSkuHandle,
        },
        bubbles: true,
      })
    );
  }

  removeParticipant(id, name, bookingResourceSkuId, resourceSkuHandle) {
    this.element.dispatchEvent(
      new CustomEvent("participantRemoved", {
        detail: {
          id,
          name,
          bookingResourceSkuId,
          resourceSkuHandle,
        },
        bubbles: true,
      })
    );
  }

  toggleParticipant(event) {
    const { id, name, bookingResourceSkuId, resourceSkuHandle } = event.detail;

    this.toggleParticipantId(id);

    if (this.nameList.includes(name)) {
      this.nameList = this.nameList.filter((el) => el !== name);
      this.removeParticipant(id, name, bookingResourceSkuId, resourceSkuHandle);
    } else if (this.bookingResourceSkuQuantityValue === 1) {
      this.removeParticipant(
        id,
        this.nameList[0],
        bookingResourceSkuId,
        resourceSkuHandle
      );
      this.nameList = [name];
      this.checkmarkTargets.forEach((checkmark) => {
        checkmark.classList.add("hidden");
      });
      this.sendParticipant(id, name, bookingResourceSkuId, resourceSkuHandle);
    } else {
      this.nameList.push(name);
      this.sendParticipant(id, name, bookingResourceSkuId, resourceSkuHandle);
    }

    this.dstTarget.innerHTML = this.nameList.join(", ");
  }

  toggleParticipantId(participantId) {
    if (this.selectedParticipantsIdsValue.includes(participantId)) {
      this.selectedParticipantsIdsValue =
        this.selectedParticipantsIdsValue.filter(
          (id) => Number(id) !== participantId
        );
    } else if (this.bookingResourceSkuQuantityValue === 1) {
      this.selectedParticipantsIdsValue = [participantId];
    } else {
      this.selectedParticipantsIdsValue = [
        ...this.selectedParticipantsIdsValue,
        participantId,
      ];
    }
  }

  highlightParticipantSelectField() {
    if (
      !this.skipQuantityCheckValue &&
      this.bookingResourceSkuQuantityValue !==
        this.selectedParticipantsIdsValue.length
    ) {
      this.participantSelectFieldTarget.classList.remove(...this.validClasses);
      this.participantSelectFieldTarget.classList.add(...this.invalidClasses);
      this.errorMessageTarget.classList.remove("hidden");
      if (this.selectedParticipantsIdsValue.length > 0) {
        this.placeholderTarget.innerHTML = this.invalidValue;
        this.placeholderTarget.title = this.invalidValue;
      } else {
        this.placeholderTarget.innerHTML = this.emptyValue;
        this.placeholderTarget.title = this.emptyValue;
      }
    } else {
      this.participantSelectFieldTarget.classList.remove(
        ...this.invalidClasses
      );
      this.participantSelectFieldTarget.classList.add(...this.validClasses);
      this.errorMessageTarget.classList.add("hidden");
      this.placeholderTarget.innerHTML = this.nameList.join(", ");
      this.placeholderTarget.title = this.nameList.join(", ");
    }
  }
}
